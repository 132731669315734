import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import ScheduleModal from "./ScheduleModal";
import { useSelector } from "react-redux";

const HomeHeader = () => {
  const [modalShow, setShowModal] = useState(false);
  const profileData = useSelector((state) => state.profileData.userData);
  const [allBatches, setAllBatches] = useState([]);

  // useEffect(() => {
  //   if (Object.keys(profileData).length > 0) {
  //     setAllBatches(profileData.batches);
  //   }
  // }, [profileData]);


  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      const filterData = profileData.batches.filter((val) => val.active === true);
      const batchList = filterData.map((data) => {
        return { label: data.batch_name, value: data._id }
      })
      setAllBatches(batchList);
    }
  }, [profileData]);

  return (
    <div className="assignment_header_container home_header_container">
      <div className="assignment_header_heading">
        <h2>Dashboard</h2>
      </div>
      <div className="assignment_header_btn home_header_btn">
        <button
          onClick={() => {
            setShowModal(true);
          }}
        >
          <span>Create Schedule</span>
        </button>
      </div>
      <ScheduleModal
        modalShow={modalShow} allBatches={allBatches} setShowModal={setShowModal} isEdit={false} />
    </div>
  );
};

export default HomeHeader;
