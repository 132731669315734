import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { ImEnlarge2 } from "react-icons/im";
// import { RiCloseLargeLine } from "react-icons/ri";
// import { RiCloseLargeFill } from "react-icons/ri";
// import { RiCloseLargeLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { confirmToast, images, notifyDanger, notifySuccess } from "../../actions/customFn";
// import axios from "../../axios";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "../../api/axios";
import SmallLoader from "../../utils/SmallLoader";
import { MdDelete } from "react-icons/md";

// import Quill from "quill";

// Extend Quill sizes
const Size = Quill.import("formats/size");
Size.whitelist = ["10px", "12px", "14px", "16px", "18px", "24px", "32px"];
Quill.register(Size, true);

const sizeLabels = {
  "10px": "10px",
  "12px": "12px",
  "14px": "14px",
  "16px": "16px",
  "18px": "18px",
  "24px": "24px",
  "32px": "32px",
};

const modules = {
  toolbar: [
    [{ size: ["10px", "12px", "14px", "16px", "18px", "24px", "32px"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ],
};

const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
  "image",
];

const ComposeEmail = ({ setToggle , setUpdateEmailList }) => {
  const [showTemplate, setShowTemplate] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [subject, setSubject] = useState("");
  const [batchList, setBatchList] = useState([]);
  const [body, setBody] = useState("");
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [templateTitle, setTemplateTitle] = useState("");
  const profileData = useSelector((state) => state.profileData.userData);
  const [isUpdate, setIsUpdate] = useState(false);
  const [swap, setSwap] = useState("batch");
  const [studentEmail, setStudentEmail] = useState("");
  const [isMailLoading, setIsMailLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      const filterData = profileData.batches.filter(
        (val) => val.active === true
      );

      const batchList = filterData.map((data) => {
        return {
          label: data.batch_name,
          value: data._id,
          program_name: data.courseInfo.title,
        };
      });
      setBatchList(batchList);
    }
  }, [profileData]);

  const getAllTemplates = () => {
    const url = "/leads/get-private-email-templates";

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        const templatemap = data?.map((data) => {
          return { label: data.template_name, value: data._id };
        });
        setTemplateList(templatemap);
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllTemplates();
  }, [isUpdate]);

  const initialState = () => {
    setSelectedBatches("");
    setSubject("");
    setBody("");
    setSelectedTemplate("");
    setTemplateTitle("");
    setStudentEmail("");
  };

  useEffect(() => {
    if (selectedTemplate) {
      const url = `/leads/get-email-template/${selectedTemplate.value}`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };

      axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          setSubject(data.template_subject);
          setBody(data.template_body);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedTemplate]);

  const sendEmailToBatches = () => {
    setIsMailLoading(true);
    const url = `/leads/batches/send-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      subject,
      body,
      batches: [selectedBatches.value],
      courseName: selectedBatches.program_name,
    };

    if (selectedTemplate?.value) {
      data.template = selectedTemplate.value;
    }

    // console.log(data)

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsUpdate((p) => !p);
        setIsMailLoading(false);
        setUpdateEmailList(p=>!p)
      })
      .catch((err) => {
        console.log(err);
        setIsMailLoading(false);

        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  const sendEmailToStudent = () => {
    setIsMailLoading(true);
    const url = `/leads/template/send-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      subject,
      body,
      email: studentEmail,
      courseName: batchList[0].program_name,
    };

    if (selectedTemplate) {
      data.template = selectedTemplate.value;
    }

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsUpdate((p) => !p);
        setIsMailLoading(false);
        setUpdateEmailList(p=>!p)

      })
      .catch((err) => {
        console.log(err);
        setIsMailLoading(false);

        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  const createTemplate = (e) => {
    e.preventDefault()
    const url = `/leads/create-email-template`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      template_name: templateTitle,
      template_subject: subject,
      template_body: body,
      template_creater: profileData._id,
      template_type: "private",
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        initialState();
        setIsUpdate((P) => !P);
        showTemplate(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTemplate = () => {
    const url = `/leads/update-email-template`;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      template_name: templateTitle,
      template_subject: subject,
      template_body: body,
      template_creater: profileData._id,
      template_type: "private",
      id: selectedTemplate.value,
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let studentErrors = {};
  let batchErrors = {};
  const [errorList, setErrorList] = useState({});

  const emailBatchValidation = (errors) => {
    if (!selectedBatches?.value) {
      errors.selectedBatches = "Please Select Batch";
    }
    if (subject?.trim().length === 0) {
      errors.subject = "Please select subject";
    }
    if (!body || body.trim() === "" || body === "<p><br></p>") {
      errors.body = "Please provide email body";
    }
    setErrorList(errors);
  };

  const emailStudentValidation = (errors) => {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(studentEmail)) {
      errors.email = "Please enter valid email";
    }
    if (studentEmail.trim().length === 0) {
      errors.email = "Please enter email";
    }
    if (subject?.trim().length === 0) {
      errors.subject = "Please select subject";
    }
    if (!body || body.trim() === "" || body === "<p><br></p>") {
      errors.body = "Please provide email body";
    }
    setErrorList(errors);
  };

  const handleSendEmail = () => {
    if (swap === "batch") {
      emailBatchValidation(batchErrors);
      if (Object.keys(batchErrors).length === 0) {
        sendEmailToBatches();
      }
    } else {
      emailStudentValidation(studentErrors);
      if (Object.keys(studentErrors).length === 0) {
        sendEmailToStudent();
      }
    }
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;

    const deleteTemplate = () => {
      const url = `/leads/delete-email-template/${data.value}`;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };

      axios
        .delete(url, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsUpdate(p=>!p)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="email_select_custom_option"
      >
        <span>{data.label}</span>
        <MdDelete
          className="text-red-500 cursor-pointer"
          size={18}
          onClick={(e) => {
            e.stopPropagation(); // Prevent dropdown selection
            confirmToast("Are you want to delete this template?" , deleteTemplate )
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className={`compose_email_box ${fullScreen && "active"}`}>
        <div className="compose_email_top">
          <h2>Compose Mail</h2>
          <span>
            <ImEnlarge2 size={20} onClick={() => setFullScreen(!fullScreen)} />
            <IoClose size={30} onClick={() => setToggle(false)} />
            {/* <RiCloseLargeFill /> */}
            {/* <RiCloseLargeLine size={20} onClick={() => setToggle(false)} /> */}
          </span>
        </div>

        <div className="compose_email_input">
          <div className="student_batch_select">
            <button
              onClick={() =>
                swap === "batch" ? setSwap("student") : setSwap("batch")
              }
            >
              <span>
                <img src={images["mail_swap.png"]} alt="" />
              </span>
              {swap === "batch" ? "Mail Student" : "Mail Batch"}
            </button>
            {swap === "batch" ? (
              <Select
                options={batchList}
                onChange={(e) => setSelectedBatches(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Active Batch"
                isClearable={true}
                value={selectedBatches}
              />
            ) : (
              <input
                placeholder="Enter email"
                className="compose_email_input_student"
                type="email"
                value={studentEmail}
                onChange={(e) => setStudentEmail(e.target.value)}
              />
            )}
          </div>
          {errorList.selectedBatches && (
            <div
              className="form_error_div d-flex justify-content-end"
              style={{ width: "52%" }}
            >
              {errorList.selectedBatches}
            </div>
          )}
          {errorList.email && (
            <div
              className="form_error_div d-flex justify-content-end"
              style={{ width: "52%" }}
            >
              {errorList.email}
            </div>
          )}
          <h2>Initial Message</h2>
          {/* <div className="compose_email_search_input">
            <BiSearch size={20} className="search_icon" />
            <input type="text" placeholder="Search for a template" />
          </div> */}

          <Select
            options={templateList}
            onChange={(e) => setSelectedTemplate(e)}
            className="basic-multi-select"
            isClearable={true}
            value={selectedTemplate}
            classNamePrefix="select"
            placeholder="Select Template"
            components={{ Option: CustomOption }}
          />
          <input
            type="text"
            className="email_sub"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {errorList.subject && (
            <div className="form_error_div">{errorList.subject}</div>
          )}

          <div className="compose_email_edit_text">
            <ReactQuill
              theme="snow"
              value={body}
              onChange={(e) => setBody(e)}
              // modules={modules}
              // formats={formats}
              // placeholder="Write something amazing..."
            />
          </div>
          {errorList.body && (
            <div className="form_error_div">{errorList.body}</div>
          )}
        </div>
        <div className="position-relative">
          <div className="compose_email_bottom">
            {selectedTemplate ? (
              <button
                onClick={() => {
                  updateTemplate();
                }}
              >
                Update Template
              </button>
            ) : (
              <button onClick={() => setShowTemplate(!showTemplate)}>
                Save as template
              </button>
            )}

            <button
              onClick={() => {
                handleSendEmail();
              }}
            >
              {isMailLoading ? <SmallLoader color={"#fff"} /> : "Send"}
            </button>
          </div>
          {showTemplate && (
            <div className="compose_email_template">
              <div className="compose_email_template_top">
                <h2>Template Title</h2>
                <RxCross2 onClick={() => setShowTemplate(false)} size={20} />
              </div>
              <form onSubmit={createTemplate}>
                <div className="compose_email_search_input">
                  {/* <BiSearch size={20} className="search" /> */}
                  <input
                    type="text"
                    placeholder="Enter Template Name"
                    value={templateTitle}
                    onChange={(e) => setTemplateTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="compose_email_save">
                  <button>Save</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ComposeEmail;
