import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { getFileExtension, uploadFileInchunk } from "../../utils/fileUpload";
import axios from "../../api/axios";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import SmallLoader from "../../utils/SmallLoader";

const ACCEPT_FILE_TYPES =
  ".doc,.docx,.pdf,.gsheet,.odt,.rtf,.txt,.xl,.xls,.csv,.py,.ipynb,.xlsx,.json,.cvs,.jpg,.jpeg,.png,.gif";

const ResourceDetailHeader = ({ setIsUpdate, folderName }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [fileCount, setFileCount] = useState(0);

  const fileUpload = async (e) => {
    if (e?.target?.files?.length > 0) {
      let files = e?.target?.files;
      let fileArr = [];
      setIsFileLoading(true);
      setFileCount(files.length);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let extension = getFileExtension(file.name);
        const fileurl = await uploadFileInchunk(file, "", false);
        let fileObj = {
          url: fileurl,
          file_name: file.name,
          file_type: extension,
        };
        fileArr.push(fileObj);
        setFileCount((p) => p - 1);
      }

      const url = `/batch/resource-update-files`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      const data = {
        id,
        files: fileArr,
      };

      axios
        .put(url, data, config)
        .then((res) => {
          const { message } = res.data;
          setIsFileLoading(false);
          setIsUpdate((p) => !p);
          notifySuccess(message);
        })
        .catch((err) => {
          setIsFileLoading(true);
          notifyDanger(err?.response?.data?.message || "Some Error Occured!");
        });
    }
  };

  return (
    <>
      <div className="resources_header">
        <div className="resources_header_heading">
          <FaAngleLeft
            size={19}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2>{folderName}</h2>
        </div>

        <label htmlFor="upload_file" className="resources_header_btn">
          {isFileLoading ? (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "3px " }}
            >
              <SmallLoader color={"#fff"} /> <span>{fileCount}</span>
            </div>
          ) : (
            "Upload file"
          )}
        </label>
        <input
          type="file"
          id="upload_file"
          className="d-none"
          accept={ACCEPT_FILE_TYPES}
          onChange={fileUpload}
          disabled={isFileLoading}
          multiple
        />
      </div>
    </>
  );
};

export default ResourceDetailHeader;
