import React, { useEffect, useState } from "react";
import { FaCaretDown, FaTrash } from "react-icons/fa6";
import { GoArrowLeft } from "react-icons/go";
import { IoIosShareAlt } from "react-icons/io";
import { images } from "../../actions/customFn";
import { MdArrowDropDown } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "../../api/axios"
import Loader from "../../utils/Loader";

const EmailChatsContent = () => {
  const { id } = useParams();
  const [emailInfo, setEmailInfo] = useState({})

  const [isLoading , setIsLoading] = useState(true )


  const getEmailHistory = () => {
    const url = `/leads/get-email/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setEmailInfo(data)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  };

  useEffect(() => {
    getEmailHistory();
  }, [id]);


  

  return (
    <>
      {isLoading ? <Loader Height={"60vh"}/> :  emailInfo && Object.keys(emailInfo).length > 0 &&
        <div className="email_chats_content">
          <div className="email_chats_content_profile_container scrollbar-template">
            <div className="email_chats_content_profile">
              <div className="email_chats_content_profile_left">
              <img
                  src={
                    emailInfo.batches.length > 0
                      ? images["batch_img.jpg"]
                      : images["email_chat_user.png"]
                  }
                  alt=""
                />
                <div className="email_chats_content_mail">
                  <h4>
                    {
                      emailInfo.batches.length > 0 ?
                        emailInfo.batches.map((val, i) => {
                          return <h3 className="active">{val.batch_name}</h3>
                        }) : <h5>{emailInfo.emailInfo}</h5>
                    }
                  </h4>
                  <span className="email_chats_content_subject">
                    {emailInfo.subject}
                  </span>

                </div>
              </div>
              <div className="email_chats_content_forward">

                <p>{moment(emailInfo.createdAt).format("DD MMM YYYY LT")}</p>
              </div>
            </div>
            <p className="mt-4 pb-5" dangerouslySetInnerHTML={{ __html: emailInfo.body }}></p>
            <div className="email_chats_content_underline"></div>
          </div>
        </div>
      }
    </>
  );
};

export default EmailChatsContent;
