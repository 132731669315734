import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import { asyncSendNotification } from "../../actions/notificationApi";
import axios from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../utils/SmallLoader";

const AssignmentShareModal = ({ modal, setModal, assignmentData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [batchId, setBatchId] = useState("");
  const [allBatches, setAllBatches] = useState([]);
  const [deadline, setDeadline] = useState("");
  const [fullBatchList, setFullbatchList] = useState([]);

  const profileData = useSelector((state) => state.profileData.userData);

  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      const filterData = profileData.batches.filter(
        (val) => val.active === true
      );
      const batchList = filterData.map((data) => {
        return { label: data.batch_name, value: data._id };
      });
      setAllBatches(batchList);
      setFullbatchList(filterData);
    }
  }, [profileData]);

  const submitAssignment = async () => {
    setIsLoading(true);
    const url = "/batch/assignment-create";
    const data = {
      title: assignmentData.title,
      description: assignmentData.description,
      instruction: assignmentData.instruction,
      deadline: deadline,
      priority: assignmentData.priority,
      batchId: batchId.value,
      work_type: assignmentData.work_type,
      assignee: profileData._id,
      resourceArr: assignmentData.resources,
    };

    const enrollStudents = fullBatchList.find(
      (batch) => batch._id === data.batchId
    );
    // console.log(data, enrollStudents.enroll_students);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        setIsLoading(false);
        notifySuccess(message);
        const studentIds = enrollStudents.enroll_students.map((doc) => {
          return doc._id;
        });
        const data = {
          recipientArr: studentIds,
          messages: {
            notification_msg: `${assignmentData.work_type} has been assigned to you!`,
            date: new Date(),
            notification_type: "work",
          },
        };
        
        initialState();
        dispatch(asyncSendNotification(data));
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.message || "Some error Occured!");
      });
  };

  const initialState = () => {
    setBatchId("");
    setDeadline("");
    setModal(false);
  };
  return (
    <div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Body>
          <div className="assignment_share_modal_body">
            <AiOutlineClose
              onClick={() => setModal(false)}
              className="close_icon"
            />
            <div className="assignment_share_modal_inp">
              <label>Select Active Batch</label>
              <Select
                isDisabled={isLoading}
                options={allBatches}
                onChange={(e) => setBatchId(e)}
                className="basic-multi-select"
                value={batchId}
                classNamePrefix="select"
                placeholder="Select Batch"
                isClearable={true}
              />
            </div>

            <div className="assignment_share_modal_inp">
              <label htmlFor="">Deadline</label>
              <input
                type="date"
                disabled={isLoading}
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
              />
            </div>
            <button disable={isLoading} onClick={submitAssignment}>
              {isLoading ? <SmallLoader color={"#fff"} /> : "Share"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AssignmentShareModal;
