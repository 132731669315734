import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { PiPlusBold } from "react-icons/pi";
// import axios from "../../axios";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import ComposeEmail from "./ComposeEmail";
import { BiUser } from "react-icons/bi";

const EmailChatsList = ({ emailList, searchQuery }) => {
  //   const [templateArr, setTemplateArr] = useState([]);
  //   const [isUpdate, setIsUpdate] = useState(false);
  //   const [allEmailList, setAllEmailList] = useState([]);
  const navigate = useNavigate();
  // console.log(allEmailList);

  //   const getAllTemplates = () => {
  //     const url = "/leads/get-all-email-templates";

  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
  //       },
  //     };

  //     axios
  //       .get(url, config)
  //       .then((res) => {
  //         const { data } = res.data;
  //         // setIsLoading(false);
  //         // setIsUpdate((p) => !p);
  //         setTemplateArr(data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         // setIsLoading(false);
  //       });
  //   };

  //   useEffect(() => {
  //     getAllTemplates();
  //   }, []);

  //   useEffect(() => {
  //     getAllEmailHistory();
  //   }, [isUpdate]);

  //   const getAllEmailHistory = () => {
  //     const url = "/leads/get-all-email-history";
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
  //       },
  //     };

  //     axios
  //       .get(url, config)
  //       .then((res) => {
  //         const { data } = res.data;
  //         setAllEmailList(data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const filterEmailList = emailList.filter((data) => {
    return (
      data.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data?.emailInfo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data?.batches?.some((itm) =>
        itm.batch_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  });

  console.log(emailList);
  //

  return (
    <>
      <div className="email_chat_list_container">
        {filterEmailList.length > 0 ? (
          filterEmailList.map((data, i) => {
            return (
              <div
                key={i}
                style={{ backgroundColor: "#F5F5F5" }}
                className="email_chat_list"
                onClick={() => navigate(`/get-email/${data._id}`)}
              >
                <img
                  src={
                    data.batches.length > 0
                      ? images["batch_img.jpg"]
                      : images["email_chat_user.png"]
                  }
                  alt=""
                />
                <div className="email_chat_list_content">
                  <span>
                    {data.batches.length > 0 ? (
                      data.batches.map((val, i) => {
                        return <h3 className="active">{val.batch_name}</h3>;
                      })
                    ) : (
                      <h5>{data.emailInfo}</h5>
                    )}
                    <h6>{moment(data.createdAt).format("DD MMM YYYY LT")}</h6>
                  </span>
                  <p>{data.subject}</p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="not-found-message">
            <img src={images["not_email.png"]} alt="" />
            <p>
              {searchQuery.length > 0
                ? "No email found for this query"
                : "No emails added yet!"}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default EmailChatsList;
