import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  AnnouncementLogo,
  AssignmentIcon,
  BatchIcon,
  DashboardIcon,
  EmailIcon,
  HelpIcon,
  QandAIcon,
  SettingIcon,
  StudentReportIcon,
} from "../../asset/icons/Icon";
import { confirmToast, images } from "../../actions/customFn";
import { FaAngleRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { CiLogout } from "react-icons/ci";
import { asyncLogOut } from "../../actions/loginAction";
import resource from "../../asset/icons/resource.svg";

const Sidebar = () => {
  const { pathname } = useLocation();
  const profileData = useSelector((state) => state.profileData.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="sidebar_container">
      <div className="sidebar_logo">
        <NavLink to={"#"}>
          <img alt="" src={images["oeson-learn-logo.png"]} />
        </NavLink>
      </div>
      <div className="sidebar_list">
        <ul>
          <li className={pathname === "/" ? "isactive" : ""}>
            <NavLink to={"/"}>
              <DashboardIcon />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className={pathname.includes("batch") ? "isactive" : ""}>
            <NavLink to={"/batch"}>
              <BatchIcon />
              <span>Batches</span>
            </NavLink>
          </li>
          <li className={pathname.includes("work") ? "isactive" : ""}>
            <NavLink to={"/work"}>
              <AssignmentIcon />
              <span>Work</span>
            </NavLink>
          </li>

        
          <li className={pathname.includes("resources") ? "isactive" : ""}>
            <NavLink to={"/resources"}>
              <img src={resource} alt="" />
              <span>Resources</span>
            </NavLink>
          </li>

          <li className={pathname.includes("email") ? "isactive" : ""}>
            <NavLink to={"/email"}>
              <EmailIcon />
              <span>Emails</span>
            </NavLink>
          </li>
          <li className={pathname.includes("qna") ? "isactive" : ""}>
            <NavLink to={"/qna"}>
              <QandAIcon />
              <span>Q And A</span>
            </NavLink>
          </li>
          <li className={pathname.includes("announcement") ? "isactive" : ""}>
            <NavLink to={"/announcement"}>
              <AnnouncementLogo />
              <span>Announcement</span>
            </NavLink>
          </li>
          <li className={pathname.includes("student-report") ? "isactive" : ""}>
            <NavLink to={"/student-report"}>
              <StudentReportIcon />
              <span>Student Report</span>
            </NavLink>
          </li>
        
          {/* <li>
            <NavLink to={"#"}>
              <HelpIcon />
              <span>help</span>
            </NavLink>
          </li> */}

          <li className={pathname.includes("profile") ? "isactive" : ""}>
            <NavLink to={"/profile"}>
              <SettingIcon />
              <span>Profile</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="sidebar_profile_box">
        <div
          className="sidebar_profile_tab"
          onClick={() => {
            confirmToast("Are you want to logout?", () => {
              dispatch(asyncLogOut(navigate));
            });
          }}
        >
          {/* <img alt="" src={profileData.image} />
          <div className="sidebar_profile_details">
            <h3>{profileData.username}</h3>
            <h4>{profileData.email}</h4>
          </div>
          <FaAngleRight /> */}
          <span>Logout</span>
          <CiLogout />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
