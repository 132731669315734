import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink, useParams } from "react-router-dom";
import { FaGoogleDrive } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaLink } from "react-icons/fa";
import {
  notifyDanger,
  notifySuccess,
  truncateString,
} from "../../actions/customFn";
import axios from "../../api/axios";
import { asyncSendNotification } from "../../actions/notificationApi";
import { useDispatch } from "react-redux";

const DUMMY_DATA = [
  {
    title: "FIle.pdf",
  },
  {
    title: "FIle.txt",
  },
  {
    title: "FIle.xl",
  },
];

const SubmissionModal = ({
  modalShow,
  setShowModal,
  data,
  setIsLoading,
  setIsUpdate,
  work_type,
  title,
}) => {
  const RatingData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [rating, setRating] = useState(1);
  const [remark, setRemark] = useState("");
  const { id } = useParams();

  const dispatch = useDispatch();

  const getFileExtension = (filename) => {
    const parts = filename.split(".");
    return parts[parts.length - 1].toLowerCase();
  };

  useEffect(() => {
    if (data) {
      if (data.rating) {
        setRating(data.rating);
      }
      if (data.remark) {
        setRemark(data.remark);
      }
    }
  }, [data]);

  const handleRatingSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `/batch/assignment/rating/`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const formData = { id, userId: data?.user?._id || null, rating, remark };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        setShowModal(false);
        notifySuccess(message);
        setIsUpdate((p) => !p);
        const formData = {
          recipientArr: [data?.user?._id],
          messages: {
            notification_msg: `
            Your ${work_type} titled <b>${title}</b> has been reviewed. Please check the rating and remarks provided for improvement.`,
            date: new Date(),
            notification_type: "work",
          },
        };
        dispatch(asyncSendNotification(formData));
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  return (
    <Modal
      centered
      className="bootstrap-modal-custom submission-modal"
      show={modalShow}
      onHide={() => setShowModal(false)}
    >
      <Modal.Body>
        <AiOutlineClose
          className="modal-close-btn"
          onClick={() => setShowModal(false)}
        />
        <div className="submission-modal-body">
          <div className="submission-modal-body-heading">
            <h2>Submission</h2>
          </div>
          <div className="submission-modal-body-inp">
            <label>Student’s Works</label>
            <div className="row">
              {data?.projectFiles.map((file, i) => {
                return (
                  <div className="col-4">
                    <div className="submission-modal-body-item">
                      <h4>
                        {file.name
                          ? truncateString(file.name, 7) +
                            "." +
                            getFileExtension(file?.url ? file.url : file)
                          : `file${i + 1}.${getFileExtension(
                              file?.url ? file.url : file
                            )}`}
                      </h4>
                      <div className="submission-modal-body-link">
                        <NavLink target="_blank" to={file?.url ? file.url : file}>
                          view
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {data?.drive_url && (
            <div className="submission-modal-body-links">
              <FaGoogleDrive />
              <NavLink to={data.drive_url} target="_blank">
                <span>google_drive_url</span>
                <FaLink />
              </NavLink>
            </div>
          )}
          {data?.postUrl && (
            <div className="submission-modal-body-links">
              <FaLinkedin />
              <NavLink to={data?.postUrl} target="_blank">
                <span>linkedin_url</span>
                <FaLink />
              </NavLink>
            </div>
          )}
          <div className="submission-modal-body-inp">
            <label>Rating</label>
            <div className="submission-modal-body-rating">
              <ul>
                {RatingData.map((data, i) => {
                  return (
                    <li
                      onClick={() => setRating(data)}
                      className={rating === data ? "active" : ""}
                    >
                      {data}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <form onSubmit={handleRatingSubmit}>
            <div className="submission-modal-body-inp">
              <label>Remark</label>
              <textarea
                rows={"3"}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                required
              />
            </div>
            <div className="submission-modal-body-submit">
              <button>Give Rating </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubmissionModal;
