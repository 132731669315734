import moment from "moment";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { Navigate, Route } from "react-router-dom";

const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace("./", "")] = r(item));
  });
  return images;
};

const images = importAll(require.context("../asset/images", false));

const checkLogin = () => {
  var status = false;
  if (localStorage.getItem("oeson_auth") != null) {
    status = true;
  }
  return status;
};

const GetRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.path) {
      return (
        <Route
          exact
          path={route.path}
          element={
            route.private === true ? (
              checkLogin() === true ? (
                route.page
              ) : (
                <Navigate to="/login" />
              )
            ) : (
              route.page
            )
          }
          key={route.key}
        />
      );
    }

    return null;
  });

function useOutsideClick(ref , setIsOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const confirmToast = (text, func) => {
  toast((t) => (
    <div className="custom-toast">
      <h3>{text}</h3>
      <div className="custom-toast-div">
        <button
          className="toast-btn-yes"
          onClick={() => {
            func();
            toast.dismiss(t.id);
          }}
        >
          Yes{" "}
        </button>
        <button className="toast-btn-no" onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </div>
    </div>
  ));
};

const notifySuccess = (notification) => toast.success(notification);

const notifyDanger = (notification) => toast.error(notification);


const getUtcTime = (time) => {
  const parsedDatetime = moment(time);
  const utcDatetime = parsedDatetime.utc();
  return utcDatetime.format("DD MMM  YYYY LT [(UTC)]");
};

const getShortString = (text , num=20)=>{
  const shorttxt = text.split("").slice(0, num);
  shorttxt.push("...");
  return shorttxt
}


const IconsImgObj = {
  doc: images["docsicon.png"],
  docx: images["docsicon.png"],
  pdf: images["pdficon.png"],
  gsheet: images["gsheeticon.png"],
  xl: images["excelicon.png"],
  xls: images["excelicon.png"],
  xlsx: images["excelicon.png"],
  py: images["pyfile.png"],
  ipynb: images["ipynbfile.png"],
  json: images["jsonfile.png"],
  jpg: images["jpegfile.png"],
  jpeg: images["jpegfile.png"],
  png:images["pngfile.png"],
  gif:images["giffile.png"],
  txt:images["txticon.png"],
  odt:images["odticon.png"],
  rtf:images["rtficon.png"],
  csv:images["csvicon.png"],
  cvs:images["cvsicon.png"]
};


function truncateString(str, maxLength) {
  return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
}

export {IconsImgObj , truncateString,   GetRoutes, images, notifySuccess, notifyDanger , useOutsideClick , confirmToast , getUtcTime , getShortString};
