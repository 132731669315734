import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import EmailHeader from "../components/emailChats/EmailHeader";
import EmailChatsList from "../components/emailChats/EmailChatsList";
import Header from "../components/layout/Header";
import axios from "../api/axios";
import Loader from "../utils/Loader";
import { PiPlusBold } from "react-icons/pi";
import ComposeEmail from "../components/emailChats/ComposeEmail";

const Email = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [toggle, setToggle] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllEmailHistory();
  }, [isUpdate]);

  const getAllEmailHistory = () => {
    const url = "/leads/get-all-email-history";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setEmailList(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <LayoutWrapper>
        <div className="email_container">
          <Header />
          <EmailHeader
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          {isLoading ? (
            <Loader Height={"60vh"} />
          ) : (
            <EmailChatsList emailList={emailList} searchQuery={searchQuery} />
          )}
          {/* <EmailChatsContent /> */}

          <div className="email_chat_cta">
            <PiPlusBold onClick={() => setToggle(!toggle)} />
          </div>
          {toggle && (
            <ComposeEmail
              setToggle={setToggle}
              setUpdateEmailList={setIsUpdate}
            />
          )}
        </div>
      </LayoutWrapper>
    </>
  );
};

export default Email;
