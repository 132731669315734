import React, { useState } from "react";
import { BiChevronLeft, BiSearch } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const EmailHeader = ({ searchQuery, setSearchQuery }) => {

  const location = useLocation();
  const navigate = useNavigate();



  return (
    <>
      <div className="email_header">
        <h2>
          {location.pathname === "/email" ? (
            ""
          ) : (
            <BiChevronLeft
              onClick={() => navigate(-1)}
              size={34}
              style={{ cursor: "pointer" }}
            />
          )}
          Email
        </h2>
        {location.pathname === "/email" && (
          <div className="email_header_right_side">
            <div className="email_header_search_input">
              <BiSearch size={20} className="search_icon" />
              <input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
        )}
      </div>

      {/*  */}
    </>
  );
};

export default EmailHeader;
