import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "../../api/axios";
import { ColorRing } from "react-loader-spinner";
import {images} from "../../actions/customFn"
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const StudentReportModal = ({ modalShow, setShowModal, ID, selectedBatch, username }) => {
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [wait, setWait] = useState(false);
  const excelFormattedData = useRef(null);

  const exportToExcel = (fileName) => {
    console.log("This function is running")
    // Convert JSON to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelFormattedData.current);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and convert it to a Blob
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Save the file using FileSaver
    saveAs(excelBlob, `${fileName}.xlsx`);
  };


  const getAllAssignment = async () => {
    try {
      const url = `batch/student-assignment-detail`;
      const paramObj = {
        batchId: selectedBatch,
        userId: ID,
      };

      const config = {
        params: paramObj,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      setWait(true);
      const res = await axios.get(url, config);
      const { data } = res.data;
      console.log(data);
      setAssignmentDetails(data);
      excelFormattedData.current = data.map(item => {
        return {
          assignment: item.title,
          status: item?.projectSubmission?.length > 0 ? 'Submitted' : 'Not Submitted',
          batch: item?.batchId.batch_name,
          rating: item.projectSubmission?.length > 0 ? item.projectSubmission[0].rating + "/10" : '--'
        }
      })
      setWait(false);
    } catch (error) {
      console.log(error)
      setWait(false);
    }

  };

  useEffect(() => {
    getAllAssignment();
  }, [ID, selectedBatch]);

  return (
    <Modal
      centered
      className="bootstrap-modal-custom comment-modal"
      show={modalShow}
      onHide={() => setShowModal(false)}
      size="xl"
    >
      <Modal.Body style={{position:"relative"}}>
        <div className="student-report-container" style={{paddingBottom:"40px"}}>
          <div style={{textAlign:"end", marginBottom:"10px"}}><AiOutlineClose style={{cursor:"pointer"}} onClick={() => setShowModal(false)} /></div>
          <div className="student-report-header">
            <p style={{ fontWeight: "500" }}>Assignment</p>
            <p style={{ fontWeight: "500" }}>Status</p>
            <p style={{ fontWeight: "500" }}>Batch Name</p>
            <p style={{ fontWeight: "500" }}>Rating (out ot 10)</p>
          </div>
          {(wait && !assignmentDetails.length) && <div style={{ display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "center", padding: "10px" }}>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#024c87', '#024c87', '#024c87', '#024c87', '#024c87']}
            />
            <p>Loading Assignments...</p>
          </div>}
          {assignmentDetails?.map((data, i) => {
            return (
              <div className="student-report-content">
                <p>{data.title}</p>
                <p style={{ fontWeight: "bold", color: data?.projectSubmission?.length > 0 ? "#1A8F78" : "#8F391A" }}>{data?.projectSubmission?.length > 0 ? 'Submitted' : 'Not Submitted'}</p>
                <p>
                  {data.batchId.batch_name}
                </p>
                <p style={{ fontWeight: "500", color: "#024c87" }}>

                  {data.projectSubmission?.length > 0
                    ? data.projectSubmission[0].rating + "/10"
                    : '--'}
                </p>
              </div>
            );
          })}
          
        </div>
        { !wait && assignmentDetails.length && <div style={{padding:"10px 10px",  marginTop:"10px", display:"flex", alignItems:"center", justifyContent:"flex-end", borderTop:"1px solid rgb(69, 75, 99)", position:"absolute", width:"100%", left:"0px", bottom:"0px", background:"#f7f8fe", borderBottomLeftRadius:"10px", borderBottomRightRadius:"10px"}}>
            <button onClick={()=>{exportToExcel(`${username?.split(" ").join("_").toUpperCase()}_STUDENT_REPORT`)}} className="modal-excel-assignment-report-btn"><img src={images["icons8-excel-32.png"]} style={{width:"24px"}} />Download</button>  
          </div>}
        {/* <AiOutlineClose
          className="modal-close-btn"
          onClick={() => setShowModal(false)}
        />
        <div className="comment-modal-body">
          <div className="comment-modal-body-heading">
            <h2>Report </h2>
          </div>
          <div className="student-report-body-div scrollbar-template">

          {assignmentDetails?.map((data, i) => {
            return (
              <div className="student-report-body-details">
                <h4>
                  {i + 1}. {data.batchId.batch_name}
                </h4>
                <h5>
                  {data.title} (
                  {data.projectSubmission?.length > 0
                    ? data.projectSubmission[0].rating + "/10"
                    : 0 + "/10"}
                  )
                </h5>
              </div>
            );
          })}
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default StudentReportModal;
