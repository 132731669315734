import React from "react";
import { MdAdd } from "react-icons/md";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const AssignmentHeader = ({ title }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  
  return (
    <div className="assignment_header_container">
      <div className="assignment_header_heading">
        <FaAngleLeft className="cursor-pointer" onClick={() => navigate(-1)} />
        <h2>{title}</h2>
      </div>
      <div className="assignment_header_btn">
        <button onClick={() => { id ? navigate(`/work/form/info/${id}`) : navigate("/work/form") }}>
          <MdAdd />
          <span>create Work</span>
        </button>
      </div>
    </div>
  );
};

export default AssignmentHeader;
