import React, { useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import AssignmentHeader from "../components/assignment/AssignmentHeader";
import Header from "../components/layout/Header";
import AllAssignmentTable from "../components/assignment/AllAssignmentTable";
import WorkBatchList from "../components/assignment/WorkBatchList";
import { useLocation, useParams } from "react-router-dom";

const Assignment = () => {
  const { pathname } = useLocation();
  const { id } = useParams()
  const [active, setActive] = useState(true)
  
  return (
    <LayoutWrapper>
      <Header />
      <AssignmentHeader title={"Work"} />
      {pathname === "/work" && <WorkBatchList active={active} setActive={setActive}  />}
      {pathname === `/work/${id}` && <AllAssignmentTable />}
    </LayoutWrapper>
  );
};

export default Assignment;
