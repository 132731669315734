import React, { useEffect, useState } from "react";
import LayoutWrapper from "../layout/LayoutWrapper";
import Header from "../layout/Header";
import ResourceDetailHeader from "./ResourcesDetailHeader";
import ResourceDetailCard from "./ResourceDetailCard";
import ResourceList from "./ResourceList";
import { useParams } from "react-router-dom";
import { images, notifyDanger } from "../../actions/customFn";
import axios from "../../api/axios";
import Loader from "../../utils/Loader";
import ResourceNotUpload from "./ResourceNotUpload";

const ResourcesDetail = () => {
  const { id } = useParams();
  const [resource, setResource] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getResources = () => {
    const url = `/batch/resource/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setResource(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        notifyDanger(err?.response?.data?.message || "Some Error Occured!");
      });
  };

  useEffect(() => {
    getResources();
  }, [id, isUpdate]);

  return (
    <>
      <LayoutWrapper>
        <Header />
        <ResourceDetailHeader
          setIsUpdate={setIsUpdate}
          folderName={resource.folder_name || ""}
        />
        <div className="resource_detail_container_div scrollbar-template">
          {/* <ResourceDetailCard /> */}
          {isLoading ? (
            <Loader Height={"60vh"} />
          ) : (
            <>
              {resource?.files?.length > 0 ? (
                <ResourceList resource={resource} setIsUpdate={setIsUpdate} />
              ) : (
                <ResourceNotUpload text="No File Has Been Uploaded" />
              )}
            </>
          )}
        </div>
      </LayoutWrapper>
    </>
  );
};

export default ResourcesDetail;
