import React from "react";
import { images } from "../../actions/customFn";

const ResourceNotUpload = ({ text }) => {
  return (
    <>
      <div className="resource_not_upload">
        <img src={images["resourcenot.png"]} alt="" />
        <h4>{text}</h4>
      </div>
    </>
  );
};

export default ResourceNotUpload;
