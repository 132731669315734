import React, { useEffect, useState } from "react";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Header from "../components/layout/Header";
import ResourceHeader from "../components/resources/ResourceHeader";

import ResourceCard from "../components/resources/ResourceCard";
import { useSelector } from "react-redux";
import { notifyDanger, notifySuccess } from "../actions/customFn";
import axios from "../api/axios";
import Loader from "../utils/Loader";

const Resources = () => {
  const profileData = useSelector((state) => state.profileData.userData);
  const [isUpdate, setIsUpdate] = useState(false);
  const [allResources, setAllResources] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const getAllResources = () => {
    const url = `/batch/resource-createdby/${profileData._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setAllResources(data);
        console.log(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };


  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      const filterData = profileData.batches.filter((val) => val.active === true);
      const batchList = filterData.map((data) => {
        return { label: data.batch_name, value: data._id }
      })
      setAllBatches(batchList);
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) getAllResources();
  }, [isUpdate, profileData]);

  return (
    <LayoutWrapper>
      <Header />
      <ResourceHeader setIsUpdate={setIsUpdate} />
      <div className="resources_card_container scrollbar-template">

        {isLoading ? <Loader Height={"60vh"} /> : <>
          <div className="resources_card_container_flex">
            {allResources?.length > 0 ? (
              allResources.map((data, i) => {
                return (
                  <ResourceCard
                    data={data}
                    index={i}
                    setIsUpdate={setIsUpdate}
                    allBatches={allBatches}
                  />
                );
              })
            ) : (
              <h6>No Folder Created Yet!</h6>
            )}
          </div>
        </>}
       
      </div>
    </LayoutWrapper>
  );
};

export default Resources;
