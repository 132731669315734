import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { images } from '../../actions/customFn';

const WorkBatchList = ({active , setActive}) => {
    const [allBatches, setAllBatches] = useState([])
    const profileData = useSelector((state) => state.profileData.userData);

    useEffect(() => {
        if (Object.keys(profileData).length > 0) {
            if (active) {
                const filterData = profileData.batches.filter((val) => val.active === true);
                setAllBatches(filterData);
            } else {
                setAllBatches(profileData.batches);
            }
        }
    }, [profileData, active]);



    return (
        <div className='work_batch_list_container'>
            <div className='work_batch_list_options'>
                <button onClick={() => setActive(true)} className={active ? "active" : ""}>Active Batch</button>
                <button onClick={() => setActive(false)} className={active === false ? "active" : ""}>All Batch</button>
            </div>
            <div className='work_batch_list_div scrollbar-template'>
                <div className='row'>
                    {allBatches.map((data, i) => {
                        return <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12'>
                            <NavLink to={`/work/${data._id}`}>
                                <div className='work_batch_list_card'>
                                    <div className='text-center'>

                                        <img alt='' src={images["work-img.png"]} />
                                    </div>
                                    <h4>{data.custom_name ? data.custom_name : data.batch_name}</h4>
                                    <button>View</button>
                                </div>
                            </NavLink>
                        </div>
                    })}

                </div>
            </div>

        </div>
    )
}

export default WorkBatchList