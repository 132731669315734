import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifyDanger, notifySuccess } from "../../actions/customFn";
import axios from "../../api/axios";
import SmallLoader from "../../utils/SmallLoader";

const ResourceHeader = ({ setIsUpdate }) => {
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profileData.userData);
  const [isLoading, setIsLoading] = useState(false);

  const createFolder = () => {
    setIsLoading(true);
    const url = "/batch/resource-create";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const data = {
      createdBy: profileData._id,
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(false);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        setIsLoading(false);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  return (
    <>
      <div className="resources_header">
        <div className="resources_header_heading">
          <FaAngleLeft
            size={19}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2>Resources</h2>
        </div>
        <button
          className="resources_header_btn"
          disabled={isLoading}
          onClick={createFolder}
        >
          {isLoading ? <SmallLoader color={"#fff"} /> : "Create Folder"}
        </button>
      </div>
    </>
  );
};

export default ResourceHeader;
