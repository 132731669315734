import React from "react";
import { PdfIcon } from "../../asset/icons/Icon";
import { motion } from "framer-motion";
import moment from "moment";
import { MdOutlineDelete } from "react-icons/md";
import {
  confirmToast,
  IconsImgObj,
  images,
  notifyDanger,
  notifySuccess,
} from "../../actions/customFn";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";

const ResourceList = ({ resource, setIsUpdate }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const deleteFile = (file) => {
    const url = `/batch/resource-delete-file`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const data = {
      id: id,
      file,
    };
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        setIsUpdate((p) => !p);
        notifySuccess(message);
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured!");
      });
  };

  // mention here

  return (
    <div>
      <motion.div
        className="dashboard_resources_detail_content_table"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Uploaded At</th>
              <th>File Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {resource?.files?.length > 0 &&
              resource?.files.map((data, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <div
                        onClick={() => window.open(data.url)}
                        className="resource_table_icon"
                      >
                        <img
                          alt=""
                          src={
                            IconsImgObj[data.file_type] ||
                            images["fileicon.png"]
                          }
                          className="resource_icon_img"
                        />
                        {data.file_name.length > 20
                          ? data.file_name.slice(0, 20) + "..." + data.file_type
                          : data.file_name}
                      </div>
                    </td>
                    <td>{moment(data.date).format("DD MM YYYY LT")}</td>
                    <td className="text-uppercase">{data.file_type}</td>
                    <td>
                      <MdOutlineDelete
                        onClick={() => {
                          confirmToast("Are you want to delete file?", () => {
                            deleteFile(data);
                          });
                        }}
                        fill="#d40808"
                        style={{
                          scale: "2",
                          marginRight: "12px",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </motion.div>
    </div>
  );
};

export default ResourceList;
