import React, { useEffect, useRef, useState } from "react";
import {
  confirmToast,
  images,
  notifyDanger,
  notifySuccess,
  useOutsideClick,
} from "../../actions/customFn";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FileIcon } from "../../asset/icons/Icon";
import Select from "react-select";
import { CompactPicker } from "react-color";
import { motion } from "framer-motion";
import { CiCircleInfo } from "react-icons/ci";
import { FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import SmallLoader from "../../utils/SmallLoader";
import moment from "moment";

const ResourceCard = ({ data, index, setIsUpdate, allBatches }) => {
  const [toggleOpt, setToggleOpt] = useState(false);
  const [isColor, setIsColor] = useState("");
  const navigate = useNavigate();
  const optionRef = useRef(null);
  const [isRename, setIsRename] = useState(false);
  const [rename, setRename] = useState(data.folder_name);
  useOutsideClick(optionRef, setToggleOpt);
  const inpRef = useRef(null);
  const [selectedBatches, setSelectedBatches] = useState("");
  const [isBatchLoading, setIsBatchLoading] = useState(false);
  const [color, setColor] = useState(data.folder_color);
  const [isColorLoading, setisColorLoading] = useState(false);

  useEffect(() => {
    if (isRename) {
      if (inpRef.current) {
        inpRef.current.focus();
      }
    }
  }, [isRename]);

  useEffect(() => {
    if (data) {
      const batches = data.batchId;
      const batchesFormat = [];
      for (let i = 0; i < batches.length; i++) {
        let existBatch = allBatches.find((val) => val.value === batches[i]);
        if (existBatch) {
          batchesFormat.push({
            label: existBatch.label,
            value: existBatch.value,
          });
        }
      }
      setSelectedBatches(batchesFormat);
    }
  }, [data]);

  const updateFolderName = (e) => {
    e.preventDefault();
    const url = "/batch/resource-update";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const formData = {
      id: data._id,
      folder_name: rename,
    };

    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        setIsRename(false);
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  const addBatchesToFolder = (e) => {
    e.preventDefault();
    if (selectedBatches && selectedBatches.length > 0) {
      setIsBatchLoading(true);
      const url = "/batch/resource-update";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      const batchId = selectedBatches.map((val) => val.value);
      const formData = {
        id: data._id,
        batchId,
      };
      axios
        .put(url, formData, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsUpdate((p) => !p);
          setSelectedBatches("");
          setIsBatchLoading(false);
          setToggleOpt(false);
        })
        .catch((err) => {
          setIsBatchLoading(false);
          notifyDanger(err.response.data.message || "Some Error Occured!");
        });
    }
  };

  const changeFolderColor = (e) => {
    setisColorLoading(true);
    const url = "/batch/resource-update-folder-color";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    const formData = {
      id: data._id,
      folder_color: color,
    };
    axios
      .put(url, formData, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
        setisColorLoading(false);
        setToggleOpt(false);
      })
      .catch((err) => {
        setisColorLoading(false);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  const deleteFolder = () => {
    const url = `/batch/resource-delete-folder/${data._id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .delete(url, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsUpdate((p) => !p);
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };

  useEffect(() => {
    if (toggleOpt === false) {
      setIsColor("");
    }
  }, [toggleOpt]);

  const downloadFolder = () => {
    setToggleOpt(false);
    if (data?.files?.length > 0) {
      const url = `/batch/resource-download-folder`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
        responseType: "blob",
      };
      const formData = {
        files: data.files,
        folder_name: data.folder_name,
      };
      axios
        .put(url, formData, config)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.folder_name + ".zip");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          notifySuccess("folder downloaded!");
        })
        .catch((err) => {
          notifyDanger(err.response.data.message || "Some Error Occured!");
        });
    } else {
      notifyDanger("can't download folder as no files found in this folder!");
    }
  };

  const openAt = () => {
    const url = `/batch/resource-openat`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    const formData = {
      id: data._id,
    };

    axios
      .put(url, formData, config)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        key={index}
        onContextMenu={(e) => {
          e.preventDefault();
          setToggleOpt(!toggleOpt);
        }}
        onDoubleClick={() => {
          navigate(`/resources/${data._id}`);
          openAt();
        }}
        className="resources_card"
      >
        <FileIcon fill={data.folder_color || "#024c87"} />
        {isRename ? (
          <form onSubmit={updateFolderName}>
            <input
              className="resource_rename_inp"
              value={rename}
              onChange={(e) => setRename(e.target.value)}
              ref={inpRef}
              required
              onBlur={(e) => {
                setIsRename(false);
                setRename(data.folder_name);
              }}
            />
          </form>
        ) : (
          <h4>
            {data?.folder_name.length > 20
              ? data.folder_name.slice(0, 20) + "..."
              : data.folder_name}
          </h4>
        )}
        <div className="resource_card_rename_options">
          <BsThreeDotsVertical
            onClick={() => setToggleOpt(!toggleOpt)}
            size={20}
          />
          {toggleOpt && (
            <motion.div
              onDoubleClick={(e) => e.stopPropagation()}
              ref={optionRef}
              className="resource_card_rename_option"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <h4
                onClick={() => {
                  navigate(`/resources/${data._id}`);
                  openAt();
                }}
              >
                <img src={images["folder-open.png"]} alt="" /> Open
              </h4>
              <h4
                onClick={() => {
                  setIsRename(true);
                  setToggleOpt(false);
                }}
              >
                <img src={images["resource_name.png"]} alt="" /> Rename
              </h4>
              <h4
                className="resource_card_share_list"
                onMouseEnter={() => setIsColor("share")}
                onMouseLeave={() => setIsColor("")}
              >
                <img src={images["resource_refer.png"]} alt="" />
                Share
                {isColor === "share" && (
                  <motion.div
                    className="resource_share_inp"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <label htmlFor="">Select Batch</label>
                    <Select
                      isMulti
                      name="colors"
                      options={allBatches}
                      onChange={(e) => setSelectedBatches(e)}
                      className="basic-multi-select"
                      value={selectedBatches}
                      classNamePrefix="select"
                      placeholder="Select Batch"
                    />
                    <button
                      disabled={isBatchLoading}
                      onClick={addBatchesToFolder}
                    >
                      {isBatchLoading ? (
                        <SmallLoader color={"#fff"} />
                      ) : (
                        "share"
                      )}
                    </button>
                  </motion.div>
                )}
              </h4>
              <h4 onClick={downloadFolder}>
                <img src={images["resource_fol.png"]} alt="" />
                Download
              </h4>
              <h4
                onMouseEnter={() => setIsColor("organize")}
                onMouseLeave={() => setIsColor("")}
                className="resource_card_color_list"
              >
                <img src={images["resource_color.png"]} alt="" />
                Organize
                {isColor === "organize" && (
                  <motion.div
                    className="resource_color_option"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="resource_color_option_colordiv">
                      <CompactPicker
                        onChange={(e) => {
                          setColor(e.hex);
                        }}
                        color={color}
                      />
                    </div>
                    <button
                      disabled={isColorLoading}
                      onClick={changeFolderColor}
                    >
                      {isColorLoading ? (
                        <SmallLoader color={"#fff"} />
                      ) : (
                        "update"
                      )}
                    </button>
                  </motion.div>
                )}
              </h4>
              <h4
                onMouseEnter={() => setIsColor("detail")}
                onMouseLeave={() => setIsColor("")}
              >
                <FiInfo size={21} />
                Details
                {isColor === "detail" && (
                  <motion.div
                    className="resource_card_detail_list"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    <span>
                      <h3>Details</h3>
                      <p>{data.files.length} files uploaded</p>
                    </span>
                    <span>
                      <h5>Created At</h5>
                      <h5>{moment(data.createdAt).format("DD MM YYYY LT")}</h5>
                    </span>
                    <span>
                      <h5>Last Modified At</h5>
                      <h5>
                        {data.modifiedAt
                          ? moment(data.modifiedAt).format("DD MM YYYY LT")
                          : "No date found!"}
                      </h5>
                    </span>
                    <span>
                      <h5>Open At</h5>
                      <h5>
                        {data.openedAt
                          ? moment(data.openedAt).format("DD MM YYYY LT")
                          : "No date found!"}
                      </h5>
                    </span>
                  </motion.div>
                )}
              </h4>
              <h4
                onClick={() =>
                  confirmToast(
                    "Are you want to delete this folder?",
                    deleteFolder
                  )
                }
              >
                <img src={images["resource_trash.png"]} alt="" />
                Move to Trash
              </h4>
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResourceCard;
