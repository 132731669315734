import AWS from "aws-sdk";
import cryptoRandomString from "crypto-random-string";
import { notifyDanger, notifySuccess } from "../actions/customFn";
import axios from "../api/axios";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  region: "us-east-1",
  useAccelerateEndpoint: true,
});

function generateUniqueKeyName(ext) {
  const timestamp = Date.now();
  const randomString = cryptoRandomString({ length: 10 });
  const uniqueKey = `${timestamp}_${randomString}.${ext}`;
  return uniqueKey;
}

export let isUploadingStatus = false;
export const uploadFile = async (
  file,
  ext,
  title,
  video,
  dispatch,
  setVideoNotification
) => {
  try {
    if (isUploadingStatus) {
      return; // Return immediately if upload is in progress
    }
    isUploadingStatus = true;
    const uniqueKey = generateUniqueKeyName(ext);
    const uploadParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: uniqueKey,
      ContentType: file.type,
      Body: file,
      ACL: "public-read",
    };

    const upload = new AWS.S3.ManagedUpload({
      params: uploadParams,
      partSize: 5 * 1024 * 1024, // 5 MB parts (adjust as needed)
      queueSize: 1, // Number of parallel uploads (adjust as needed)
    });

    let startTime = Date.now();
    let prevUploadedBytes = 0;

    upload.on("httpUploadProgress", (progressData) => {
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTime) / 1000; // elapsed time in seconds
      const uploadedBytes = progressData.loaded;
      const totalBytes = progressData.total;

      const speed = (uploadedBytes - prevUploadedBytes) / elapsedTime; // upload speed in bytes per second
      prevUploadedBytes = uploadedBytes;

      const percentUploaded = (uploadedBytes / totalBytes) * 100;
      if (video) {
        dispatch(
          setVideoNotification({
            title,
            uploadedpercent: percentUploaded.toFixed(),
            estimated_time_in_min: 0,
            estimated_time_in_sec: 0,
          })
        );
      }

      const remainingBytes = totalBytes - uploadedBytes;
      let estimatedTime;
      if (video && speed > 0) {
        estimatedTime = remainingBytes / speed; // estimated time in seconds
        if (estimatedTime < 60) {
          estimatedTime = Math.ceil(estimatedTime); // Round up to the nearest second
          // console.log("Estimated Time (seconds):", estimatedTime);
          dispatch(
            setVideoNotification({
              title,
              uploadedpercent: percentUploaded.toFixed(),
              estimated_time_in_min: 0,
              estimated_time_in_sec: estimatedTime,
            })
          );
        } else {
          estimatedTime = Math.ceil(estimatedTime / 60); // Convert to minutes
          // console.log("Estimated Time (minutes):", estimatedTime);
          dispatch(
            setVideoNotification({
              title,
              uploadedpercent: percentUploaded.toFixed(),
              estimated_time_in_min: (estimatedTime / 100).toFixed(0),
              estimated_time_in_sec: 0,
            })
          );
        }
      }
    });

    const data = await upload.promise();

    let videoUrl = data.Location;
    notifySuccess("File uploaded successfully!");
    isUploadingStatus = false;
    if (video) {
      dispatch(
        setVideoNotification({
          title: "",
          uploadedpercent: 0,
          estimated_time_in_min: 0,
          estimated_time_in_sec: 0,
        })
      );
    }
    return videoUrl;
  } catch (error) {
    console.error("Error uploading file:", error);
    notifyDanger("File cannot be uploaded!");
    throw new Error("Cannot upload file");
  }
};

export const getFileExtension = (filename) => {
  const parts = filename.split(".");
  return parts[parts.length - 1].toLowerCase();
};

export const uploadFileInchunk = async (
  file,
  title,
  video,
  dispatch,
  setVideoNotification
) => {
  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

  let extension = getFileExtension(file.name);
  let fileName = generateUniqueKeyName(extension);

  let uploadId = null;
  const partsArray = [];

  let uploadedBytes = 0;
  const startTime = Date.now();

  let chunkIndex;

  for (chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * CHUNK_SIZE;
    const end = Math.min(file.size, start + CHUNK_SIZE);
    const chunk = file.slice(start, end);

    const updateProgress = (uploadedSoFar) => (progressEvent) => {
      if (video) {
        const chunkProgress = progressEvent.loaded;
        const totalUploadedBytes = uploadedSoFar + chunkProgress;

        // Calculate progress percentage
        const percentComplete = Math.round(
          (totalUploadedBytes / file.size) * 100
        );
        dispatch(
          setVideoNotification({
            title,
            uploadedpercent: percentComplete,
            estimated_time_in_min: 0,
            estimated_time_in_sec: 0,
          })
        );

        // Calculate elapsed time and speed
        const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
        const uploadSpeed = totalUploadedBytes / elapsedTime; // Bytes per second

        // Calculate estimated time remaining
        const remainingBytes = file.size - totalUploadedBytes;
        let estimatedTime = remainingBytes / uploadSpeed; // Time in seconds

        if (estimatedTime < 60) {
          estimatedTime = Math.ceil(estimatedTime); // Round up to the nearest second
          // console.log("Estimated Time (seconds):", estimatedTime);
          dispatch(
            setVideoNotification({
              title,
              uploadedpercent: percentComplete,
              estimated_time_in_min: 0,
              estimated_time_in_sec: estimatedTime,
            })
          );
        } else {
          estimatedTime = Math.ceil(estimatedTime / 60); // Convert to minutes
          // console.log("Estimated Time (minutes):", estimatedTime);
          dispatch(
            setVideoNotification({
              title,
              uploadedpercent: percentComplete,
              estimated_time_in_min: estimatedTime,
              estimated_time_in_sec: 0,
            })
          );
        }
      }
      return;
    };

    const formData = new FormData();
    formData.append("fileChunk", chunk);
    formData.append("fileName", fileName);
    formData.append("chunkIndex", chunkIndex);
    formData.append("totalChunks", totalChunks);
    formData.append("mimeType", file.type);

    if (!uploadId && chunkIndex === 0) {
      // Initiate multipart upload
      const response = await axios.post("/course/upload/initiate", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      });
      uploadId = response.data.uploadId;
    }

    formData.append("uploadId", uploadId);
    formData.append("partNumber", chunkIndex + 1);

    const partResponse = await axios.post("/course/upload/part", formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      onUploadProgress: updateProgress(uploadedBytes),
    });

    uploadedBytes += chunk.size;
    partsArray.push({
      ETag: partResponse.data.partETag,
      PartNumber: chunkIndex + 1,
    });
  }

  // Complete multipart upload
  const completeResponse = await axios.post(
    "/course/upload/complete",
    {
      uploadId,
      partsArray,
      fileName: fileName,
      mimeType: file.type,
      totalChunks,
      chunkIndex,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    }
  );

  if (video) {
    dispatch(
      setVideoNotification({
        title: "",
        uploadedpercent: 0,
        estimated_time_in_min: 0,
        estimated_time_in_sec: 0,
      })
    );
  }

  notifySuccess("file uploaded successfully!");

  return completeResponse.data.location || completeResponse.data;
};
