import axios from "../api/axios"
import { notifyDanger, notifySuccess } from "./customFn";


export const asyncSendNotification = (data) => {
  return (dispatch) => {
    const url = `/notification/send-notfication`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;

      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};




export const asyncSendEmail = (data) => {
  return (dispatch) => {
    const url = `/leads/template/send-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message)

      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};


export const asyncSendEmailToBatches = (data) => {
  return (dispatch) => {
    const url = `/leads/batches/send-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message)

      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};